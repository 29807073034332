import React from 'react'
import propTypes from 'prop-types'

// Render inline SVG
export default function CustomSVG(props) {
  if (props.content) {
    return <span className={props.className} dangerouslySetInnerHTML={{ __html: props.content }} />
  }

  return null
}

CustomSVG.propTypes = {
  content: propTypes.string
}
