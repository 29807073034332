import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import featuredImg from '../assets/images/un-sanctions-featured-optimized.png'

const detailsQuery = graphql`
  query SEOQuery {
    sanityAppSettings {
      metaTitle
      metaDescription
    }
  }
`

function SEO({ description, lang, meta, keywords = [], title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        if (!data.sanityAppSettings) {
          return
        }

        data = data.sanityAppSettings

        const metaTitle = data.metaTitle || ''
        const metaDescription = description || data.metaDescription
        const metaAuthor = data.metaAuthor || ''

        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={title}
            titleTemplate={title === metaTitle ? '%s' : `%s | ${metaTitle}`}
            meta={[
              {
                name: 'description',
                content: metaDescription
              },
              {
                property: 'og:title',
                content: title
              },
              {
                property: 'og:description',
                content: metaDescription
              },
              {
                property: 'og:image',
                content: featuredImg
              },
              {
                property: 'og:type',
                content: 'website'
              },
              {
                name: 'twitter:card',
                content: 'summary'
              },
              {
                name: 'twitter:creator',
                content: metaAuthor
              },
              {
                name: 'twitter:title',
                content: title
              },
              {
                name: 'twitter:description',
                content: metaDescription
              },
              {
                property: 'twitter:image',
                content: featuredImg
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', ')
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: []
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO
