import React from 'react'

import Tabs from '../mui-components/Tabs/Tabs'
import Chips from '../mui-components/Chips/Chips'

const analogyFinderTabs = props => {
  const { data } = props

  const tabs = []

  // Create Tabs and their Chips items
  data.forEach(tabSection => {
    const items = tabSection.data.nodes.map(item => {
      return {
        key: item.id,
        value: item.id,
        active: false,
        label: item.title
      }
    })

    tabs.push({
      label: tabSection.label,
      content: <Chips items={items} location={props.location} typeSlug={tabSection.slug} />
    })
  })

  return <Tabs tabs={tabs} location={props.location} />
}

export default analogyFinderTabs
