import React, { useState, useEffect } from 'react'

// we need to use router and not gatsby's navigate because of "go back" functionality and replace option
import { navigate } from '@reach/router'

import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import SearchBar from './SearchBar'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  toolbar: {
    minHeight: '62px',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    maxWidth: theme.breakpoints.values.md + 'px',
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '19px',
      paddingRight: theme.spacing(2)
    }
  },
  backIcon: {
    fontSize: theme.typography.pxToRem(16)
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(1),
    color: props =>
      props.color === 'secondary' ? theme.palette.primary.main : theme.palette.common.white,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  searchIconGray: {
    opacity: 0.75,
    color: theme.palette.grey[700]
  },
  searchIconWhite: {
    color: 'rgba(255, 255, 255, 0.74)'
  }
})

const TopBar = props => {
  const [showBack, setShowBack] = useState(props.showBack)
  const [showSearch, setShowSearch] = useState(0)

  const { classes } = props
  const searchQuery =
    props.location && props.location.search
      ? new URLSearchParams(props.location.search).get('keywords')
      : ''
  const searchIconClass =
    props.color === 'secondary' || showSearch ? classes.searchIconGray : classes.searchIconWhite

  const isSearchPage = props.location && props.location.pathname === '/search/'
  const isHomepage = props.location && props.location.pathname === '/'

  // Run this only once!
  useEffect(() => {
    if (isSearchPage) {
      setShowSearch(1)
    }
  }, [])

  const handleShowSearch = () => {
    setShowSearch(1)
    setShowBack(1)
  }

  const handleSearchInputChange = value => {
    // Add history when coming from different page, otherwise use replace instead
    const shouldReplace = !!isSearchPage

    // Navigate to search page when started to type
    navigate('/search/?keywords=' + value, { replace: shouldReplace })
  }

  const handleSearchInputCancel = () => {
    // Navigate to search page when started to type
    navigate('/search/?keyword', { replace: true })
  }

  const handleBackButton = () => {
    if (!isSearchPage && showSearch) {
      // When we are showing search and not currently on the search page, just hide search bar
      setShowSearch(0)

      // If we are on homepage, also hide back button
      if (isHomepage) {
        setShowBack(0)
      }
    } else if (props.location.state && props.location.state.referrer) {
      // State is set only when we came to current url directly from the app
      // Go back
      navigate(-1)
    } else {
      // In all other cases, use fallback and navigate to homepage
      navigate('/')
    }
  }

  return (
    <AppBar
      position="fixed"
      className={classes.root}
      color={props.color === 'secondary' || showSearch ? 'secondary' : 'primary'}
      elevation={3}
    >
      <Toolbar className={classes.toolbar}>
        {showBack || showSearch ? (
          <IconButton aria-label="back" color="inherit" onClick={handleBackButton}>
            <ArrowBackIosIcon fontSize="small" className={classes.backIcon} />
          </IconButton>
        ) : null}
        {showSearch ? (
          <SearchBar
            placeholder="Start typing"
            value={searchQuery}
            onChange={handleSearchInputChange}
            onCancelSearch={handleSearchInputCancel}
          />
        ) : (
          <>
            <Typography variant="h1" className={classes.title}>
              {props.title}
            </Typography>
            <IconButton aria-label="search" color="inherit" onClick={handleShowSearch}>
              <SearchIcon className={searchIconClass} />
            </IconButton>
          </>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default withStyles(styles)(TopBar)
