import React from 'react'
import PropTypes from 'prop-types'

import { formatStringByNumber } from '../../lib/helpers'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Box from '@material-ui/core/Box'

import NumberTab from './NumberTab'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.primary.light,
    boxShadow: theme.shadows[3]
  },
  appBar: {
    boxShadow: theme.shadows[2],
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.primary.light,
    maxWidth: theme.breakpoints.values.md + 'px',
    margin: '0 auto',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      padding: theme.spacing(0, 2)
    }
  },
  AppBarLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1, 2),
    // backgroundColor: theme.palette.primary.light,
    boxShadow: theme.shadows[2],
    fontSize: theme.typography.body2.fontSize
  },
  tabs: {
    // backgroundColor: theme.palette.primary.light,
    color: 'black',
    flex: 1
  },
  scroller: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  indicator: {
    display: 'none'
  },
  tabPanel: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

NumberTabs.propTypes = {
  handleTabClick: PropTypes.func
}

export default function NumberTabs(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(props.value)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const tabsItems = props.tabs.map((tab, index) => {
    return (
      <NumberTab
        to={tab.to}
        icon={tab.number}
        label={tab.label}
        value={tab.number}
        key={index}
        handleTabClick={props.handleTabClick}
      />
    )
  })

  return (
    <div className={classes.root}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        {props.showEpisodesCount ? (
          <Box className={classes.AppBarLabel}>
            <div>{formatStringByNumber(props.counterLabel, tabsItems.length)}</div>(
            {tabsItems.length})
          </Box>
        ) : null}

        <Tabs
          classes={{ root: classes.tabs, scroller: classes.scroller, indicator: classes.indicator }}
          value={props.value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable links"
        >
          {tabsItems}
        </Tabs>
      </AppBar>
    </div>
  )
}
