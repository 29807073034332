import React from 'react'
// we need to use router and not gatsby's navigate because of replace option
import { navigate } from '@reach/router'

import NumbersTabs from '../mui-components/Tabs/NumbersTabs'

const factsBar = props => {
  const { data } = props
  let currentValue = false // no tab would be active

  const handleTabClick = to => {
    // Navigate to the new page, but do not add tab pages to history when on single episode
    navigate(to, { replace: true })
  }

  const numbersTabsData = data.nodes.map(node => {
    const linkTo = node.path

    // Set value for active tab only in case we are on particular Single Episode
    if (props.location.pathname.indexOf(linkTo) !== -1) {
      currentValue = node.orderNumber
    }

    return {
      number: node.orderNumber,
      to: linkTo
    }
  })

  return <NumbersTabs tabs={numbersTabsData} value={currentValue} handleTabClick={handleTabClick} />
}

export default factsBar
