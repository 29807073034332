import React from 'react'
import { format } from 'date-fns'
// we need to use router and not gatsby's navigate because of replace option
// TODO: check if still valid in Gatsby v3
import { navigate } from '@reach/router'

import { getDateRangeArr } from '../lib/helpers'

import NumbersTabs from '../mui-components/Tabs/NumbersTabs'

const episodesBar = props => {
  const { data } = props
  let currentValue = false // no tab would be active

  const numbersTabsData = data.nodes.map(node => {
    const linkTo = node.path

    // Set value for active tab only in case we are on particular Single Episode
    if (props.location.pathname.indexOf(linkTo) !== -1) {
      currentValue = node.episodeNumber
    }
    const startDateFormated = node.startDate ? format(new Date(node.startDate), 'yyyy') : null
    const endDateFormated = node.endDate ? format(new Date(node.endDate), 'yyyy') : null
    return {
      number: node.episodeNumber,
      to: linkTo,
      label: getDateRangeArr(startDateFormated, endDateFormated, <div key={node.id}>-</div>, 'Now')
    }
  })

  const handleTabClick = to => {
    // Check with currentValue if we are on single episode or not
    const shouldReplace = currentValue ? true : false

    // Navigate to the new page, but do not add tab pages to history when on single episode
    navigate(to, { replace: shouldReplace })
  }

  return (
    <NumbersTabs
      tabs={numbersTabsData}
      showEpisodesCount
      value={currentValue}
      counterLabel="Episode"
      handleTabClick={handleTabClick}
    />
  )
}

export default episodesBar
