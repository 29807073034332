import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import MUIAppBar from '@material-ui/core/AppBar'
import MUITabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import Tab from './Tab'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box py={2} px={1} boxShadow={1} bgcolor="secondary.main">
          {children}
        </Box>
      )}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  appBar: {
    backgroundColor: theme.palette.primary.light
  },
  tabs: {
    color: 'black',
    maxWidth: theme.breakpoints.values.md + 'px',
    margin: '0 auto',
    width: '100%'
  },
  scroller: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  indicator: {
    display: 'none'
  },
  tabPanel: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    maxWidth: theme.breakpoints.values.md + 'px',
    margin: '0 auto',
    width: '100%'
  }
}))

export default function Tabs(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const tabsItems = props.tabs
    ? props.tabs.map((tab, index) => {
        return <Tab label={tab.label} key={index} index={index} />
      })
    : null

  const tabsPanelItems = props.tabs
    ? props.tabs.map((tab, index) => {
        return (
          <TabPanel value={value} key={index} index={index} className={classes.tabPanel}>
            {tab.content}
          </TabPanel>
        )
      })
    : null

  return (
    <div className={classes.root}>
      <MUIAppBar position="static" classes={{ root: classes.appBar }}>
        <MUITabs
          classes={{ root: classes.tabs, scroller: classes.scroller, indicator: classes.indicator }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="off"
          aria-label="scrollable tabs"
        >
          {tabsItems}
        </MUITabs>
      </MUIAppBar>
      {tabsPanelItems}
    </div>
  )
}
