import React from 'react'
import { navigate } from 'gatsby-link'

import { useAppSettings } from '../../hooks/useAppSettings'

import { BottomNavigation, BottomNavigationAction } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CustomSVG from '../../components/custom-svg'

const useStyles = makeStyles(theme => {
  return {
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0
    },
    item: {
      minWidth: theme.typography.pxToRem(50),
      maxWidth: '125px'
    },
    wrapper: {
      '& svg': {
        width: theme.typography.pxToRem(21),
        height: theme.typography.pxToRem(21),
        pointerEvents: 'none'
      }
    },
    itemLabel: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: theme.typography.pxToRem(0.4),
      '&.selected': {
        color: theme.palette.primary.main,
        fontSize: theme.typography.pxToRem(13)
      }
    },
    hide: {
      '@media (max-width:768px)': {
        display: 'none'
      }
    }
  }
})

export default function SimpleBottomNavigation(props) {
  const classes = useStyles()

  //Load data for bottom navigation items
  const { _rawFooterMenu } = useAppSettings()

  // Get parent slug from url, so we can set proper active item
  const pathname = props.location.pathname
  const pathnameArr = pathname.split('/')
  let currentValue = pathnameArr[1] ? '/' + pathnameArr[1] : '/'

  // Check for /pages/ slugs (e.g. Designing sanctions page)
  if (pathnameArr[1] === 'pages') {
    currentValue =
      pathnameArr[1] && pathnameArr[2] ? '/' + pathnameArr[1] + '/' + pathnameArr[2] : '/'
  }

  // Check for another custom pages, which should set nav item active
  _rawFooterMenu.forEach(item => {
    // Get active slugs and split them (there can be more slugs than one)
    const activeSlugs = item.isActiveSlugs ? item.isActiveSlugs.split(',') : []

    // Now check if current url contains one of active slugs
    // Then we need to set currentValue to actual item link to fake BottomNavigation and set proper active item
    activeSlugs.forEach(slug => {
      if (pathname === slug || pathname.indexOf(slug) !== -1) {
        currentValue = item.linkTo
      }
    })
  })

  // Navigate to another page
  const changeHandler = (event, newValue) => {
    navigate(newValue)
  }

  const actionClasses = {
    root: classes.item,
    selected: 'selected',
    wrapper: classes.wrapper,
    label: classes.itemLabel
  }

  // create menu item for each item in the appSettings footer menu array
  const menuItems = _rawFooterMenu.map(item => {
    return (
      <BottomNavigationAction
        label={item.title}
        classes={actionClasses}
        className={item.hideOnMobile ? classes.hide : ''}
        icon={item.icon ? <CustomSVG content={item.icon.iconSource} /> : ''}
        value={item.linkTo}
        key={item._key}
      />
    )
  })

  return (
    <BottomNavigation
      value={currentValue}
      onChange={changeHandler}
      classes={{
        root: classes.root
      }}
      showLabels
      component={props.component}
    >
      {menuItems}
    </BottomNavigation>
  )
}
