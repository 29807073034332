import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import MUITab from '@material-ui/core/Tab'

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: props => (props.label ? 'flex-start' : 'center'),
    maxWidth: '50px',
    minWidth: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: theme.palette.common.white,
    opacity: 1,
    lineHeight: 1.1,
    fontSize: theme.typography.pxToRem(8),
    fontWeight: '400'
  },
  selected: {},
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: '500',
    // Apply selected styles only when noneSelected prop is not used
    '$selected &': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black
    }
  },
  tabWrapper: {
    width: '25px'
  }
}))

NumberTab.propTypes = {
  handleTabClick: PropTypes.func
}

// Modify Tab component to act like a link and display circular number with optional small label
export default function NumberTab(props) {
  const classes = useStyles(props)
  const { handleTabClick, ...otherProps } = props

  return (
    <MUITab
      {...otherProps}
      component="a"
      onClick={() => handleTabClick(props.to)}
      value={props.value}
      icon={<span className={classes.icon}>{props.icon}</span>}
      classes={{ root: classes.root, selected: classes.selected, wrapper: classes.tabWrapper }}
    />
  )
}
