import React, { useEffect } from 'react'
import { navigate } from 'gatsby-link'

import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  resetBox: {
    width: '100%',
    textAlign: 'right'
  },
  resetButton: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.grey[500],
    '& svg': {
      marginTop: '-1px'
    }
  },
  chip: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  }
}))

export default function Chips(props) {
  const classes = useStyles()

  const chips = props.items ?? []

  const [chipData, setChipData] = React.useState(chips)

  // Run only when 'props.location.search' change
  useEffect(() => {
    const url = props.location ? props.location.search : ''
    const newChips = chips.map(item => {
      return url.indexOf(item.value) !== -1 ? { ...item, active: true } : item
    })

    setChipData(newChips)
  }, [props.location.search])

  // Toggle chip active state
  const handleClick = chipToToggle => () => {
    // Get all url params
    // URL format: analogy-finder?generalObjective=ID1,ID2,ID3&sanctionPurpose=ID12
    const urlParamsAll = new URLSearchParams(props.location.search)

    // Get params only for current tab type
    const urlParamsForType = urlParamsAll.has(props.typeSlug)
      ? urlParamsAll.getAll(props.typeSlug)
      : []

    // Url params for type can have multiple values separated by comma (,)
    let urlParamsInner =
      urlParamsForType.length > 0 && urlParamsForType[0] ? urlParamsForType[0].split(',') : []

    const modifiedChips = [...chipData].map(chip => {
      if (chip.key === chipToToggle.key) {
        chip.active = !chip.active

        // Check if chip is active and add or remove param from url
        if (chip.active) {
          urlParamsInner.push(chipToToggle.value)
        } else {
          urlParamsInner = urlParamsInner.filter(item => {
            return item !== chipToToggle.value
          })
        }
      }

      return chip
    })

    // Delete type parameter from slug and replace it with the new one
    urlParamsAll.delete(props.typeSlug)
    if (urlParamsInner) {
      urlParamsAll.append(props.typeSlug, urlParamsInner.join(','))
    }

    // Finally navigate to the new page with applied url args
    navigate(props.location.pathname + '?' + urlParamsAll.toString())

    setChipData(modifiedChips)
  }

  const handleClear = chipToToggle => () => {
    const modifiedChips = [...chipData].map(chip => {
      chip.active = false
      return chip
    })

    // Get all url params
    // URL format: analogy-finder?generalObjective=ID1,ID2,ID3&sanctionPurpose=ID12
    const urlParamsAll = new URLSearchParams(props.location.search)

    // Remove url args only from current tab and navigate to the new page
    urlParamsAll.delete(props.typeSlug)
    // Navigate to the new page, but do not add pages to history
    navigate(props.location.pathname + '?' + urlParamsAll.toString(), { replace: true })

    setChipData(modifiedChips)
  }

  return (
    <div className={classes.root}>
      {chipData.map(data => {
        return (
          <Chip
            key={data.key}
            icon={data.active ? <DoneIcon /> : null}
            value={data.value}
            label={data.label}
            onClick={handleClick(data)}
            color={data.active ? 'primary' : 'default'}
            classes={{ root: classes.chip }}
            variant={data.active ? 'default' : 'outlined'}
          />
        )
      })}
      <Box className={classes.resetBox}>
        <Button
          startIcon={<ClearIcon />}
          size="small"
          onClick={handleClear()}
          className={classes.resetButton}
        >
          Reset
        </Button>
      </Box>
    </div>
  )
}
