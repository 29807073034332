import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import MUITab from '@material-ui/core/Tab'

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(33, 33, 33, 0.08)',
    marginRight: theme.spacing(1),
    color: theme.palette.common.white,
    opacity: 1,
    maxWidth: '120px',
    minWidth: 'auto',
    lineHeight: 1.1
  },
  selected: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    boxShadow: theme.shadows[1]
  }
}))

export default function Tab(props) {
  const classes = useStyles()

  return (
    <MUITab
      classes={{ root: classes.root, selected: classes.selected }}
      {...props}
      {...a11yProps(props.index)}
    />
  )
}
