// Service workers update handler
// Source: https://www.ziebikiewicz.com/adding-update-button-to-gatsby-app

import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const UPDATE_CHECKING_INTERVAL = 60 * 60 * 24 * 14 * 1000 // Check for updates every 2 weeks

export default class UpdateButton extends Component {
  state = {
    showButton: false,
    updateHandler: null,
    infoText: ''
  }

  componentDidMount() {
    this.registerServiceWorker()
  }

  registerServiceWorker = () => {
    if (
      typeof window === 'undefined' ||
      typeof navigator === 'undefined' ||
      !navigator.serviceWorker
    ) {
      return
    }

    navigator.serviceWorker.register('/sw.js').then(reg => {
      if (!navigator.serviceWorker.controller) {
        return
      }

      // Check for SW update every X ms
      setInterval(() => {
        reg.update()
      }, UPDATE_CHECKING_INTERVAL)

      if (reg.waiting) {
        this.updateReady(reg.waiting)
      } else if (reg.installing) {
        this.trackInstalling(reg.installing)
      } else {
        reg.addEventListener('updatefound', () => {
          this.trackInstalling(reg.installing)
        })
      }
    })

    // Listen for the controlling service worker changing
    // and reload the page
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
  }

  trackInstalling = worker => {
    worker.addEventListener('statechange', () => {
      if (worker.state === 'installed') {
        this.updateReady(worker)
      }
    })
  }

  updateReady = worker => {
    this.setState({
      showButton: true,
      infoText: 'There is an update for the app',
      updateHandler: () => {
        this.setState({ showButton: false })
        // Tell the service worker to skipWaiting
        worker.postMessage({ action: 'skipWaiting' })
      }
    })
  }

  handleUpdate = () => {
    if (typeof this.state.updateHandler === 'function') {
      this.state.updateHandler()
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.setState({ showButton: false })
  }

  render() {
    if (!this.state.showButton) return null

    return (
      <Snackbar
        open
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        message={this.state.infoText}
        ContentProps={{ elevation: 1 }}
        action={
          <>
            <Button onClick={this.handleUpdate} color="inherit" size="small">
              Update
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    )
  }
}
