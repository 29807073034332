import { format, formatDistance } from 'date-fns'

import Theme from '../shared/global'

export function cn(...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes(data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current
}

export function getBlogUrl(publishedAt, slug) {
  return `/blog/${format(publishedAt, 'YYYY/MM')}/${slug.current || slug}/`
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id }
  }

  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function dashifyString(string) {
  return string ? string.replace(/ /g, '-') : ''
}

// Check if app is opened in standalone mode
// It means app is downloaded and opened in built-in browser
export function matchStandalone() {
  if (typeof window !== 'undefined') {
    const matchStandalone = window.matchMedia('(display-mode: standalone)')

    return matchStandalone && matchStandalone.matches
  }

  return false
}

// Prepare overall effectiveness tag object from OverallEffectivness object
export function getOverallEffectivenessObject(overallEffectiveness) {
  if (overallEffectiveness && overallEffectiveness.title) {
    let tagLabel = null
    let tagColor = null
    if (overallEffectiveness.title == 'Overall low') {
      tagLabel = 'Ineffective'
      tagColor = Theme.palette.error.main
    }

    if (overallEffectiveness.title == 'Overall mixed') {
      tagLabel = 'Mixed'
      tagColor = Theme.palette.warning.main
    }

    if (overallEffectiveness.title == 'Overall high') {
      tagLabel = 'Effective'
      tagColor = Theme.palette.success.main
    }
    return {
      tagLabel: tagLabel,
      tagColor: tagColor
    }
  }
  return null
}

// We need to use an array because we may want to wrap dates in HTML in React components
export function getDateRangeArr(startDate, endDate, joinString, openDateText) {
  const arr = []
  joinString = joinString || ' to '
  openDateText = openDateText || 'Present'

  if (startDate) {
    arr.push(startDate)
  }

  if (endDate) {
    arr.push(joinString)
    arr.push(endDate)
  } else {
    arr.push(joinString)
    arr.push(openDateText)
  }

  return arr
}

// Distance between 2 dates, e.g. What is the distance between 2 July 2014 and 1 January 2015? => '6 months'
export function getDateDistanceHumanReadable(startDate, endDate) {
  return startDate && endDate ? formatDistance(new Date(startDate), new Date(endDate)) : null
}

export function getEpisodesCountFormatted(number) {
  return number !== 1 ? number + ' episodes' : number + ' episode'
}

// Simple helper for adding 's' when string is in plural form
export function formatStringByNumber(string, number) {
  return number !== 1 ? string + 's' : string
}

export function getCountriesCountFormatted(number) {
  return number !== 1 ? number + ' country regimes' : number + ' country regime'
}

// Source: https://stackoverflow.com/a/56757215
export function removeArrayDuplicates(array, keyToCompare) {
  return array.filter((v, i, a) => a.findIndex(t => t[keyToCompare] === v[keyToCompare]) === i)
}

// Source: https://gist.github.com/robmathers/1830ce09695f759bf2c4df15c29dd22d
export function groupByObjectProperty(data, key) {
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce(function (storage, item) {
    // get the first instance of the key by which we're grouping
    var group = item[key]

    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || []

    // add this item to its group within `storage`
    storage[group].push(item)

    // return the updated storage to the reduce function, which will then loop through the next
    return storage
  }, {}) // {} is the initial value of the storage
}

// Sort episodes by title
export function sortArrayByTitle(array, titleProp) {
  array.sort(function (a, b) {
    var nameA = a[titleProp].toUpperCase() // ignore upper and lowercase
    var nameB = b[titleProp].toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

  return array
}

export function getPageTypeFromPathname(props) {
  const location = props.location ? props.location.pathname : ''

  if (!location) {
    return null
  }

  if (location.indexOf('/episodes/') !== -1) {
    return 'episodes'
  }

  if (location.indexOf('/types-of-sanctions') !== -1) {
    return 'types-of-sanctions'
  }

  if (location.indexOf('/types-of-sanctions/') !== -1) {
    return 'single-type-of-sanctions'
  }
}

/**
 * Effectivness calculator based on rules:
 * - Effective: policy outcome 4 or 5 AND sanctions contribution 3, 4, or 5
 * - Mixed: policy outcome 3 AND sanctions contribution 3, 4, or 5.
 * - Ineffective: all other cases, i.e. policy outcome 0, 1, or 2 (regardless of sanctions contribution, which can be 0-5)
 *   OR policy outcome 3, 4, or 5 when sanctions contribution is 0, 1, or 2.
 */
export function getEffectivenessScore(applicable, policyScore, contributionScore) {
  let result = { label: 'N/A', value: '-1' }
  if (applicable) {
    policyScore = parseInt(policyScore)
    contributionScore = parseInt(contributionScore)

    if (policyScore > 3 && contributionScore > 2) {
      result = { label: 'Effective', value: '3' }
    }

    if (policyScore === 3 && contributionScore > 2) {
      result = { label: 'Mixed', value: '2' }
    }

    if (policyScore < 3 || contributionScore < 3) {
      result = { label: 'Ineffective', value: '1' }
    }
  }
  return result
}

/* Return visibility of section based on section type and content */
export function isSectionVisible(sectionData) {
  const type = sectionData._type

  // Every section must have settings
  // if (!sectionData.sectionSettings) {
  //   return false
  // }

  // Content Section
  if (type === 'contentSection') {
    if (
      sectionData._rawSectionContent ||
      sectionData.sectionContent ||
      (sectionData.sectionSettings.highlightedTitle && sectionData.sectionSettings.sectionTitle)
    ) {
      return true
    }
  }

  // Cases Section
  if (type === 'casesSection') {
    if (sectionData.episodes) {
      return true
    }
  }

  // Selected redolutions Section
  if (type === 'selectedResolutionsSection') {
    if (sectionData.resolutions) {
      return true
    }
  }

  // Multicontent Section
  if (type === 'multiContentSection') {
    if (sectionData.resolutionSections) {
      return true
    }
  }

  // Overview Section
  if (type === 'overviewSection') {
    const status = sectionData.status
    const objectives = sectionData.objectives
    const sanctionTypes = sectionData.sanctionTypes
    const regionalContent = sectionData.regionalContent
    const unilateralContent = sectionData.unilateralContent
    const policyInstrument = sectionData.policyInstrument
    const customAttributes = sectionData.customAttributes
    if (
      status ||
      objectives ||
      sanctionTypes ||
      regionalContent ||
      unilateralContent ||
      policyInstrument ||
      customAttributes
    ) {
      return true
    }
  }
  // Colored box Section
  if (type === 'coloredBoxSection') {
    const title = sectionData.sectionSettings ? sectionData.sectionSettings.sectionTitle : null
    const link = sectionData.linkToPage ? sectionData.linkToPage : sectionData.linkToURL
    const description = sectionData.sectionDescription
    if (title || description || (link && (title || description))) {
      return true
    }
  }
  // Purposes Section
  if (type === 'purposesSection') {
    if (
      sectionData.purposesSection ||
      sectionData._rawConstrainContent ||
      sectionData._rawSignalContent
    ) {
      return true
    }
  }
  // Santion type Section
  if (type === 'sanctionTypeSection') {
    if (sectionData._rawContent || sectionData.content || sectionData.sanctionType) {
      return true
    }
  }

  // Potential Scope Section
  if (type === 'potentialScopeSection') {
    /*if (sectionData._rawSectionContent || sectionData.sectionContent) {
      return true
    }*/
    return true // Visible also if content is missing.
  }

  // Effectiveness Section
  if (type === 'effectivenessSection') {
    const coercionContributionContent = sectionData._rawCoercionContributionContent
    const coercionPolicyContent = sectionData._rawCoercionPolicyContent
    const constraintContributionContent = sectionData._rawConstraintContributionContent
    const constraintPolicyContent = sectionData._rawConstraintPolicyContent
    const signalingContributionContent = sectionData._rawSignalingContributionContent
    const signalingPolicyContent = sectionData._rawSignalingPolicyContent
    if (
      coercionContributionContent ||
      coercionPolicyContent ||
      constraintContributionContent ||
      constraintPolicyContent ||
      signalingContributionContent ||
      signalingPolicyContent
    ) {
      return true
    }
  }
  // Resolution guide Section
  if (type === 'resolutionsGuideSection') {
    if (sectionData.resolutions) {
      return sectionData.resolutions.length > 0
    }
  }

  return false
}

/**
 * Get unique cases
 */
export function getUniqueCases(episodes) {
  let uniqueCases = []
  const cases = []

  if (episodes) {
    episodes.forEach(episode => {
      if (episode) {
        cases.push({
          startDate: episode.hasOwnProperty('inCase') ? episode.inCase.startDate : '',
          endDate: episode.hasOwnProperty('inCase') ? episode.inCase.endDate : '',
          title: episode.hasOwnProperty('inCase') ? episode.inCase.title : '',
          id: episode.hasOwnProperty('inCase') ? episode.inCase.id : '',
          slug: episode.hasOwnProperty('inCase') ? episode.inCase.slug : '',
          episodes: []
        })
      }
    })

    // Remove duplicates
    uniqueCases = removeArrayDuplicates(cases, 'id')

    // At last, add episodes to proper cases
    episodes.forEach(episode => {
      uniqueCases.find((item, index) => {
        if (episode && episode.hasOwnProperty('inCase') && item.id === episode.inCase.id) {
          uniqueCases[index].episodes.push(episode)
        }
      })
    })
  }

  return uniqueCases
}
